<template>
    <div id="accountSelect" class="max-w-[1200px] mx-auto h-screen">
        <div class=" w-full flex justify-center items-center gap-5">
            <p class="w-full col-span-2 border-b-2 border-[#CFD7E6] text-2xl font-semibold mt-20 mb-10 text-center text-[#5D6470] pb-2">{{$t('select_account')}}</p>
        </div>
        <div class="w-full flex justify-center items-center gap-5">
                <div v-for="accountObj, index in $store.state.accountRes" @click="sendToSubdomain(accountObj)" :key="'account' + index"  class="col-span-1 h-24 realtive bg-white rounded-md shadow-md p-5 cursor-pointer hover:shadow-xl transition-all duration-300">
                    <!--<div class="h-[72px] relative mb-5">
                        <img class="w-full max-h-[72px] absolute top-0 bottom-0 left-0 right-0 m-auto" :src="accountObj.account.image" :alt="accountObj.account.image">
                    </div>-->
                    <p class=" text-black font-bold mb-2">{{accountObj.account.name}}</p>
                    <p class="line-clamp-4 text-sm text-gray-600">{{accountObj.subdomain}}</p>
                </div>
        </div>
    </div>
</template>

<script>
import Loader from "@/mixins/Loader"
export default {
    mixins: [
    Loader
    ],
    methods: {
        sendToSubdomain: function (account) {
            if (account.account.business) {
                window.location.replace(`https://${account.subdomain}.homerunner.com`)
            } else {
                window.location.replace(`https://account.coolrunner.dk/login/o?token=${this.$store.state.auth.access_token}&expires_in=${this.$store.state.auth.expires / 1000 - Date.now()}`);
            }
        }
    },
};
</script>